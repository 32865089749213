import {mapActions, mapGetters, mapMutations} from "vuex";
import {StreamBarcodeReader} from "vue-barcode-reader";

export default {
    name: "game",
    components: {
        StreamBarcodeReader
    },
    data() {
        return {
            checked: true,
            allPersonage: true,
            disable: false,
            toDonate: false,
            toGift: false,
            donated: false,
            openQr: false,
            qrResponse: '',
            passedPersonages: '',
            isFinishDelivery: false,
            userName: '',
            savedUserData: false
        }
    },
    watch: {
        isDonated() {
            this.isDonated ? this.disable = false : this.disable = true
            this.isDonated ? this.donated = true : this.donated = false
        },

        deliveryData() {
            this.deliveryData.status ? this.isFinishDelivery = true : this.isFinishDelivery = false
        },
        personageData() {
            let sorted = this.personageData.filter(el => el.isOpened)
            this.passedPersonages = sorted.length
        }

    },
    created() {
        this.getUserInfo()
        this.getUserData()
    },
    beforeUpdate() {

    },
    mounted() {

        document.getElementById('html').classList.remove('hide')
        this.getAuthUserData().then(() => {
            this.userName = this.authUserData.name.split(' ')[0]
        })
        if (!this.personageData) {
            this.getPersonsData().then(() => {
                let sorted = this.personageData.filter(el => el.isOpened)
                this.passedPersonages = sorted.length
            })
        } else {
            let sorted = this.personageData.filter(el => el.isOpened)
            this.passedPersonages = sorted.length
        }

    },

    computed: {
        ...mapGetters({
            isDonated: 'setting/checkedDonate',
            isNoEmptyDelivery: 'setting/deliveryData',
            personageData: 'game/gameData',
            userInfo: 'game/userInfo',
            deliveryData: 'setting/deliveryData',
            deliveryContent: 'setting/deliveryContent',
            userData: 'order/userData',
            authUserData: 'game/authUserData'
        }),
        isShowButtonHeroes() {
            return this.$route.path.includes('heroes')
        },
        isShowButtonDonate() {
            return this.$route.path.includes('donate')
        },
        isShowButtonGift() {
            return this.$route.path.includes('gift') && !this.userInfo.phone
        },

        isDisabledButtonDonate() {
            return this.donated || this.userInfo.stepNumber >= 2
        },
        isDisabledButtonHeroes() {
            return this.passedPersonages === 10
        },
        isDisabledButtonGift() {
            if (this.userInfo.phone) {
                return false
            } else {
                return this.isFinishDelivery
            }

        }
    },

    methods: {
        ...mapMutations({
            showFirstPersonage: 'popups/SHOW_FIRST_PERSONAGE',
            showGiftPopup: 'popups/SHOW_GIFT_PRODUCT',
            allPersonages: 'popups/SHOW_ALL_PERSONS_POPUP'

        }),
        ...mapActions({
            getUserInfo: 'game/GET_USER_INFO',
            getUserData: 'game/GET_GAME_DATE',
            openPersonage: 'game/GET_PERSON',
            getPersonsData: 'game/GET_GAME_DATE',
            saveUserDelivery: 'order/SEND_USER_DATA',
            getAuthUserData: 'game/GET_USER_AUTH_INFO'

        }),
        goToDonate() {
            this.$router.push({name: 'donate'}).catch(() => {
                console.log()
            })
        },
        goToGift() {
            return this.$router.push({name: 'gift'}).catch(() => {
                console.log()
            })
        },

        onDecode(respnse) {
            window.location.href = respnse
            this.$refs.streamReader.codeReader.stream.getTracks().forEach(function (track) {
                track.stop()
            })
        },
        onLoaded() {
            console.log(`Ready to start scanning barcodes`)
        },
        openQrReader() {
            if (this.openQr) {
                this.$refs.streamReader.codeReader.stream.getTracks().forEach(function (track) {
                    track.stop()
                })
            }


            this.openQr = !this.openQr
        },
        saveUserData() {
            let obj = {}
            obj.name = this.deliveryData.content.name
            obj.phone = this.deliveryData.content.phone.trim()
            obj.delivery_address = this.deliveryData.content.deliveryType.desc
            obj.delivery_city = this.deliveryData.content.city
            obj.delivery_number = this.deliveryData.content.department
            obj.data_processing_confirmation = this.deliveryData.content.checked

            this.saveUserDelivery(obj).then(() => {
                this.isFinishDelivery = false
            })

        },
        goHome() {
            this.$router.push({name: 'main'})
        }
    }
}
